import { render, staticRenderFns } from "./packing.vue?vue&type=template&id=5fd17757&scoped=true"
import script from "./packing.vue?vue&type=script&lang=js"
export * from "./packing.vue?vue&type=script&lang=js"
import style0 from "./packing.vue?vue&type=style&index=0&id=5fd17757&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fd17757",
  null
  
)

export default component.exports